<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <logo />
        <h2 class="brand-text text-primary ml-1">
          HomeDoc
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img
            fluid
            src="@/assets/images/pages/login-v2.svg"
            alt="Login V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            class="mb-1 font-weight-bold"
            title-tag="h2"
          >
            Đăng nhập! 👋
          </b-card-title>
          <b-card-text class="mb-2">
            HomeDoc - Bác sĩ của gia đình
          </b-card-text>

          <!-- form -->
          <validation-observer
            ref="loginForm"
            #default="{ invalid }"
          >
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent="login"
            >
              <!-- phone -->
              <b-form-group
                label="Tên đăng nhập/Email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="username"
                  rules="required|email"
                >
                  <b-form-input
                    id="username"
                    v-model="username"
                    :state="errors.length > 0 ? false : null"
                    name="username"
                    placeholder="username@email.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group label="Mật khẩu">

                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="password"
                      v-model="password"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="password"
                      placeholder="Mật khẩu"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- checkbox -->
              <div class="d-flex justify-content-between">
                <b-form-group>
                  <b-form-checkbox
                    id="remember-me"
                    v-model="status"
                    name="checkbox-1"
                  >
                    Ghi nhớ đăng nhập
                  </b-form-checkbox>
                </b-form-group>
                <b-link :to="{ name: 'auth-forgot-password' }">
                  <small>Quên mật khẩu?</small>
                </b-link>
              </div>

              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
                :disabled="invalid"
              >
                Đăng nhập
              </b-button>
            </b-form>
          </validation-observer>

        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import Logo from '@/layouts/components/Logo.vue';
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  VBTooltip,
} from 'bootstrap-vue';
// eslint-disable-next-line import/no-cycle
import useJwt from '@/auth/jwt/useJwt';
import { required } from '@validations';
import { togglePasswordVisibility } from '@core/mixins/ui/forms';
// eslint-disable-next-line import/no-cycle
import { getHomeRouteForLoggedInUser, getUserAbilities } from '@/auth/utils';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    Logo,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      username: '',
      password: '',
      status: '',

      // validation rules
      required,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon';
    },
  },
  methods: {
    login() {
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          useJwt
            .login({
              username: this.username,
              password: this.password,
            })
            .then(response => {
              console.log(response.data);
              const { token } = response.data;
              useJwt.setToken(token);
              useJwt.setRefreshToken(token);

              useJwt.getUserProfile().then(responseData => {
                console.log(responseData);
                const { user } = responseData.data;
                const ability = getUserAbilities(user.role);
                localStorage.setItem('userData', JSON.stringify({ ...responseData.data.user, ability }));
                this.$ability.update(ability);

                this.$router
                  .replace(getHomeRouteForLoggedInUser('admin'))
                  .then(() => {
                    this.$toast({
                      component: ToastificationContent,
                      position: 'top-right',
                      props: {
                        title: `Xin chào ${user.full_name}`,
                        icon: 'UserIcon',
                        variant: 'success',
                        text: `Bạn đang đăng nhập với vai trò ${user.role.display_name}!`,
                      },
                    });
                  });
              })
                .catch(error => {
                  console.log(error);
                });

              // localStorage.setItem('userData', JSON.stringify(user));
            })
            .catch(error => {
              // console.log(error);
              if (error.response && error.response.data && error.response.data.error) {
                this.$refs.loginForm.setErrors(error.response.data.error);
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Thông báo',
                    text: 'Lỗi hệ thống. Vui lòng chờ trong giây lát hoặc liên hệ hỗ trợ kỹ thuật để được trợ giúp kịp thời. Hotline liên hệ: 0888666888',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                });
              }
            });
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
